/* eslint-disable import/extensions */
import { loadSelf } from "@fdn/profile_store";
import Env from "Consts/env";
import cookie from "cookie";
import {
	adsDetik,
} from "Helpers/utils";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { END } from "redux-saga";
import vars from "Consts/vars";
import BannerPopUp from "Components/banner-popup/BannerPopUp";

import { loadArticleCampaign } from "@/Store/ArticleCampaign/lib/actions";
import { loadArticleEducation } from "@/Store/ArticleEducation/lib/actions";
import { loadArticleMost } from "@/Store/ArticleMost/lib/actions";
import { loadArticleParenting } from "@/Store/ArticleParenting/lib/actions";
import { loadArticlePopular } from "@/Store/ArticlePopular/lib/actions";
import { loadArticleRelation } from "@/Store/ArticleRelation/lib/actions";
import { loadArticleWork } from "@/Store/ArticleWork/lib/actions";
import { loadArticleZone } from "@/Store/ArticleZone/lib/actions";
import { loadDiscoverVideo } from "@/Store/DiscoverVideo/lib/actions";
import { wrapper } from "@/Store/index";
import { loadMenuNavbar } from "@/Store/MenuNavbar/lib/actions";
import { loadQuiz } from "@/Store/Quiz/lib/actions";
import { loadReview } from "@/Store/Review/lib/actions";
import { loadYoutubeVideo } from "@/Store/YoutubeVideo/lib/actions";
import { loadArticleMidlife } from "@/Store/ArticleMidlife/lib/actions";

const Layout = dynamic(() => import("Containers/layout"));

const MetaHead = dynamic(() => import("Containers/meta_head"));

const HomeContainer = dynamic(() => import("Containers/home_container"));

const getBanner = async (params) => {

	try {

		const options = {
			method: "GET",
			headers: {
				placement: params.placement,
				device: params.device,
				key: process.env.NEXT_PUBLIC_KEY_API,
			},
		};

		const response = await fetch(`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getBanner`, options);
		const data = await response.json();

		return data;

	} catch (err) {

		throw new Error("Error fetch data banner", err);

	}

};

const Index = (props) => {

	const prop = props;
	const dispatch = useDispatch();
	const storeData = useSelector((state) => state);

	const [ArticleDetik, setArticleDetik] = useState([]);
	const [isActiveButton, setIsActiveButton] = useState("product");
	const [bannerPopup, setBannerPopup] = useState(null);

	const RSSFetch = (params) => {

		const datas = new window.DOMParser().parseFromString(
			params.data,
			"text/xml",
		);

		const items = datas.querySelectorAll("item");

		const feedItems = [...items].map((el) => ({
			link: el.querySelector("link").innerHTML,
			title: el
				.querySelector("title")
				.innerHTML.replace(/^<!\[CDATA\[|\]\]>$/g, ""),
			image: el.querySelector("description").innerHTML.split("\"")[1],
			category: params.cat,
		}));

		return feedItems[0];

	};

	const handleGetBenner = () => {

		if (![0, 1].includes(performance.navigation.type)) {

			sessionStorage.clear();

		}

		const currentImage = window.sessionStorage.getItem("existing-banner-image");

		getBanner({
			placement: prop.isMobile
				? vars.BANNER.POPUP_BANNER_MOBILE : vars.BANNER.POPUP_BANNER_DESKTOP,
		}).then((data) => {

			// Set current image to session storage
			const popupBanner = data?.data?.[0];

			if (popupBanner?.image) {

				window.sessionStorage.setItem("existing-banner-image", popupBanner?.image);
				if (popupBanner?.image !== currentImage) {

					window.sessionStorage.removeItem("opened-banner");

				}

			}

			if ((popupBanner?.placement === vars.BANNER.POPUP_BANNER_MOBILE
				|| popupBanner?.placement === vars.BANNER.POPUP_BANNER_DESKTOP)
				&& (popupBanner?.image !== currentImage)) {

				setBannerPopup(data?.data?.length > 0 ? popupBanner : null);

				// setDataLayer()?.gtm({
				// 	event: "view_popup_banner",
				// 	username: storeData.storeSelf?.data?.username || "guest",
				// 	is_login: !!storeData.storeSelf?.data?.username,
				// 	bannername: popupBanner.name,
				// 	placement: prop.isMobile
				// 		? vars.BANNER.POPUP_BANNER_MOBILE : vars.BANNER.POPUP_BANNER_DESKTOP,
				// });

			}

		}).catch((err) => {

			setBannerPopup(null);
			console.log("Error Banner ", err);

		});

	};

	useEffect(() => {

		const time = 5 * 60 * 1000;
		const timeId = setInterval((function firstImidiateAction() {

			handleGetBenner();
			return firstImidiateAction;

		}()), time);

		return () => {

			clearInterval(timeId);

		};

	}, []);

	useEffect(() => {

		const handleBunda = async () => {

			const res = await fetch("/rss/bunda");
			const dataRSS = await res.text();
			const dataBunda = RSSFetch({ data: dataRSS, cat: "HAI BUNDA" });
			return dataBunda;

		};
		const handleInsert = async () => {

			const res = await fetch("/rss/insert");
			const dataRSS = await res.text();
			const dataInsert = RSSFetch({ data: dataRSS, cat: "INSERT LIVE" });
			return dataInsert;

		};
		const handleBeauty = async () => {

			const res = await fetch("/rss/beauty");
			const dataRSS = await res.text();
			const dataBeauty = RSSFetch({ data: dataRSS, cat: "BEAUTYNESIA" });
			return dataBeauty;

		};

		Promise.all([handleBunda(), handleInsert(), handleBeauty()])
			.then((results) => {

				setArticleDetik(results);

			})
			.catch((error) => {

				console.error(error);

			});

		// client fetch

		dispatch(
			loadReview({
				slug: "product",
			}),
		);

		dispatch(
			loadArticleEducation(),
		);

		dispatch(
			loadArticleWork({
			}),
		);

		dispatch(
			loadArticleCampaign({
				limit: prop.isMobile ? 5 : 6,
			}),
		);

		dispatch(
			loadArticleParenting(),
		);

		dispatch(
			loadArticleRelation(),
		);

		dispatch(
			loadQuiz(),
		);

	}, []);

	const handleReview = (params) => {

		dispatch(
			loadReview({
				slug: params,
			}),
		);

		if (isActiveButton === params) {

			setIsActiveButton(null);

		} else {

			setIsActiveButton(params);

		}

	};

	return (
		<>
			{
				bannerPopup && (
					<BannerPopUp
						url={bannerPopup.url}
						name={bannerPopup.name}
						image={bannerPopup.image}
						description={bannerPopup.description}
						isMobile={prop.isMobile}
						username={storeData.storeSelf?.data?.username || "guest"}
						format={bannerPopup.format}
					/>
				)
			}
			<Layout
				home
				title="Mommiesdaily"
				ismobile={prop.isMobile}
				self={storeData.storeSelf}
				adsHead={prop.adsHead}
				adsFoot={prop.adsFoot}
				navbarMenu={storeData.storeMenuDrawer}
			>
				<MetaHead />

				<div className="container">
					<HomeContainer
						isMobile={prop.isMobile}
						ArticlePopular={storeData.storeArticlePopular}
						ArticleZone={storeData.storeArticleZone}
						ArticleMost={storeData.storeArticleMost}
						ArticleCampaign={storeData.storeArticleCampaign}
						ArticleParenting={storeData.storeArticleParenting}
						ArticleEducation={storeData.storeArticleEducation}
						ArticleRelation={storeData.storeArticleRelation}
						ArticleWork={storeData.storeArticleWork}
						DiscoverVideo={storeData.storeDiscoverVideo}
						Quiz={storeData.storeQuiz}
						Review={storeData.storeReview}
						ArticleDetik={ArticleDetik}
						MenuNavbar={storeData.storeMenuNavbar}
						handleReview={handleReview}
						active={isActiveButton}
						youtubeMeta={prop.youtubeMeta}
					/>
				</div>
			</Layout>
		</>
	);

};

export const getServerSideProps = wrapper.getServerSideProps(
	async ({
		store, req, res, query,
	}) => {

		res.setHeader(
			"Cache-Control",
			"public, s-maxage=10, stale-while-revalidate=59",
		);

		let userAgent;
		let cookies;
		let authorization = null;

		if (req) {

			// if you are on the server and you get a "req" property from your context
			userAgent = req.headers["user-agent"]; // get the user-agent from the headers

		} else {

			// if you are on the client you can access the navigator from the window object
			userAgent = navigator.userAgent;

		}

		const isMobile = userAgent && Boolean(
			userAgent.match(
				/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
			),
		);

		const isServer = !!req;

		if (req.headers.cookie) {

			cookies = cookie.parse(req.headers.cookie);
			authorization = cookies.token;

		}

		if (authorization) {

			await store.dispatch(
				loadSelf({
					payload: {
						api_env: process.env.NEXT_PUBLIC_NODE_ENV,
						env: Env.HEADERS,
						Authorization: authorization,
						is_server: isServer,
						is_allo: process.env.NEXT_PUBLIC_ALLO_STATUS === "ON",
					},
				}),
			);

		}

		await store.dispatch(
			loadArticlePopular(),
		);

		await store.dispatch(
			loadArticleZone({
				limit: isMobile ? 4 : 10,
			}),
		);

		await store.dispatch(
			loadArticleMost({
				page: 1,
				limit: 5,
			}),
		);

		await store.dispatch(
			loadMenuNavbar(),
		);

		await store.dispatch(loadDiscoverVideo());

		await store.dispatch(loadArticleMidlife({ page: 1 }));

		const queryVideoYoutube = {
			channelId: process.env.NEXT_PUBLIC_YOUTUBE_MOMMIESDAILY_CHANNEL_ID,
			order: "date",
			part: "snippet",
			type: "video",
			maxResults: "7",
			regionCode: "ID",
			q: process.env.NEXT_PUBLIC_MIDLIFE_YOUTUBE_TAG ?? "",
		};
		await store.dispatch(loadYoutubeVideo({
			query: {
				medium: new URLSearchParams({ ...queryVideoYoutube, videoDuration: "medium" }),
				long: new URLSearchParams({ ...queryVideoYoutube, videoDuration: "long" }),
			},
		}));

		store.dispatch(END);
		await store.sagaTask.toPromise();

		const adsHead = await adsDetik("taghead", isMobile);
		const adsFoot = await adsDetik("tagfoot", isMobile);

		let youtubeMeta = null;
		try {

			const YOUTUBE_QUERY = new URLSearchParams({
				channelId: process.env.NEXT_PUBLIC_YOUTUBE_CHANNEL_ID,
				order: "date",
				part: "snippet",
				type: "video",
				maxResult: "1",
				q: process.env.NEXT_PUBLIC_YOUTUBE_WHATSONFD_TAG ?? "",
			});
			const YOUTUBE_URL = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getYoutubeVideo?${YOUTUBE_QUERY}`;
			const youtubeListResponse = await fetch(YOUTUBE_URL);
			const youtube = await youtubeListResponse.json();

			youtubeMeta = youtube?.items?.[0] ?? null;

		} catch (_error) {

			youtubeMeta = null;

		}

		return {
			props: {
				query,
				isMobile,
				adsHead,
				adsFoot,
				youtubeMeta,
			}, // will be passed to the page component as props
		};

	},
);

export default Index;
