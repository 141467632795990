import PropTypes from "prop-types";
import ShowBennerPopUp from "./ShowBennerPopUp";

const Mobile = ({
	url,
	name,
	image,
	title,
	onClose,
	onClickDataLayer,
	description,
	format,
}) => (
	<div>
		<div className="banner-popup-bg-filter" onClick={onClose} aria-hidden="true" />
		<div
			id="banner-popup-banner-popup"
			className="banner-popup-container"
		>
			<div className="banner-popup-overlay" />
			<div className="banner-popup-wrapper">
				<div className="banner-popup-content bg-transparent">
					<div
						className="banner-popup-close-campaign"
						onClick={() => onClose()}
						aria-hidden="true"
					>
						<span />
						<span />
					</div>
					<div className="banner-popup-banner" onClick={onClickDataLayer} aria-hidden="true">
						{url ? (
							<a href={url} title={title || ""} target="_blank" rel="noreferrer" className="banner-popup-banner-button" aria-label={title}>
								<ShowBennerPopUp
									isMobile
									format={format}
									image={image}
									name={name}
									description={description}
								/>
							</a>
						) : (
							<div className="banner-popup-banner-button">
								<ShowBennerPopUp
									isMobile
									format={format}
									image={image}
									name={name}
									description={description}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
		{/*
        need to be jsx global but global property get error
        Unknown property 'global' foundeslintreact/no-unknown-property
    */}
		<style>
			{`
          .banner-popup-bg-filter {
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            position: fixed;
            background: rgba(0, 0, 0, 0.75);
            z-index: 998;
          }
          .banner-popup-container{
            position: fixed;
            z-index: 999;
            width: max-content;
            height: max-content;
            background-color: transparent;
            padding: 20px 0;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            -webkit-animation-name: animatezoom;
            -webkit-animation-duration: 0.4s;
            animation-name: animatezoom;
            animation-duration: 0.4s;
          }
          .banner-popup-container .banner-popup-overlay{
            background: transparent;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
          }
          .banner-popup-container .banner-popup-wrapper{
            display: flex;
            width: 100%;
            height: auto;
            justify-content: center;
            margin: 0 auto !important;
            max-width: inherit !important;
          }
          .banner-popup-container .banner-popup-wrapper .banner-popup-content{
            overflow: visible;
            overflow-y: visible;
            width: auto;
            max-width: inherit;
            background: transparent;
            padding: 0;
            margin: 0 12px;
            background: #fff;
            position: relative;
            z-index: 1;
            border-radius: 6px;
          }
          .banner-popup-banner {
            z-index: 1;
            position: relative;
            padding: 0!important;
            max-height: max-content;
            overflow-y: hidden;
          }
          .banner-popup-banner .banner-popup-banner-button {
            width: 100%;
            height: 100%;
            display: block;
            outline: 0;
            text-decoration: none;
          }
          .bg-transparent {
            background-color: transparent !important;
          }
          .banner-popup-close-campaign {
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 50%;
            background-color: #fff;
            top: -25px;
            right: 0;
            border: none;
          }
          .banner-popup-close-campaign span {
            width: 15px;
            height: 2px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
            display: block;
            background-color: #000;
            position: absolute;
            top: 47.5%;
            left: 4.5px;
          }

          .banner-popup-close-campaign span:nth-child(1) {
            transform: rotate(45deg);
          }

          .banner-popup-close-campaign span:nth-child(2) {
            transform: rotate(-45deg);
          }
       `}
		</style>
	</div>

);

Mobile.propTypes = {
	url: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	onClickDataLayer: PropTypes.func.isRequired,
	description: PropTypes.string.isRequired,
	format: PropTypes.string.isRequired,
};

export default Mobile;
