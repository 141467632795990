import PropTypes from "prop-types";

const ImageShow = ({
	isMobile, image, description, name,
}) => {

	if (isMobile) {

		return (
			<>
				<img
					src={image}
					alt={description}
					data-popup={name}
					className="banner-popup-banner-image"
				/>
				<style jsx>
					{`
						.banner-popup-banner-image {
							width: 320px;
							height: 500px;
						}

						@media screen (max-width: 320px) {
							.banner-popup-banner-image {
								width: 280px;
								height: 437.5px;
							}
						}
					`}
				</style>
			</>
		);

	}

	return (
		<>
			<img
				src={image}
				alt={description}
				data-popup={name}
				className="banner-popup-banner-image"
			/>
			<style jsx>
				{`
					.banner-popup-banner-image {
						position: relative !important;
						width: 800px;
						height: 500px;
						max-height: 500px;
						margin: 0 auto;
						object-fit: contain;
					}
				`}
			</style>
		</>
	);

};

ImageShow.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};

const VideoShow = ({
	isMobile, image,
}) => {

	if (isMobile) {

		return (
			<>
				<video
					className="banner-popup-banner-image"
					controls
					controlsList="nofullscreen nodownload"
					muted
					autoPlay
					loop
				>
					<source src={image} type="video/mp4" />
				</video>
				<style jsx>
					{`
						.banner-popup-banner-image {
							width: 320px;
							height: 500px;
						}

						@media screen (max-width: 320px) {
							.banner-popup-banner-image {
								width: 280px;
								height: 437.5px;
							}
						}
					`}
				</style>
			</>
		);

	}

	return (
		<>
			<video
				className="banner-popup-banner-image"
				controls
				controlsList="nofullscreen nodownload"
				muted
				autoPlay
				loop
			>
				<source src={image} type="video/mp4" />
			</video>
			<style jsx>
				{`
					.banner-popup-banner-image {
						position: relative !important;
						width: 800px;
						height: 500px;
						max-height: 500px;
						margin: 0 auto;
						object-fit: contain;
					}
				`}
			</style>
		</>
	);

};

VideoShow.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	image: PropTypes.string.isRequired,
};

const ShowBennerPopUp = ({
	isMobile, format, image, name, description,
}) => {

	if (format === "video") {

		return <VideoShow isMobile={isMobile} image={image} />;

	}

	return (
		<ImageShow
			isMobile={isMobile}
			name={name}
			image={image}
			description={description}
		/>
	);

};

ShowBennerPopUp.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	format: PropTypes.bool.isRequired,
	image: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};

export default ShowBennerPopUp;
