export default {
	FETCH_INIT: "fetch_init",
	FETCH_LOADING: "fetch_loading",
	FETCH_ERROR: "fetch_error",
	FETCH_RESULT: "fetch_result",
	COLOR_TAB: [
		{
			id: 0,
			color: "#D45473",
		},
		{
			id: 1,
			color: "#4F9593",
		},
		{
			id: 2,
			color: "#ECB966",
		},
		{
			id: 3,
			color: "#624786",
		},
	],
	top_menu: {
		title_menu: {
			text: "",
			link: {
				desktop: "//app.femaledaily.com",
				mobile: {
					android:
						"https://play.google.com/store/apps/details?id=com.fdbr.android",
					ios: "https://apps.apple.com/id/app/female-daily-beauty-review/id1160742672",
				},
			},
		},
		menus: [
			{
				text: "MD REVIEWS",
				link: process.env.NEXT_PUBLIC_MD_REVIEW,
				id: null,
				new: false,
			},
			// {
			// 	text: "FEMALE DAILY STUDIO",
			// 	link: process.env.NEXT_PUBLIC_ECM_DOMAIN,
			// 	id: null,
			// 	new: false,
			// },
		],
	},
	config_mommies: [
		{
			name: "MD REVIEWS",
			value: "reviews",
			pathname: process.env.NEXT_PUBLIC_MD_REVIEW,
			query: "",
			visible: true,
			new: false,
		},
	],

	SisterSites: [
		{
			name: "Female Daily",
			value: "home",
			pathname: process.env.NEXT_PUBLIC_ROOT_HOME,
			query: "",
			visible: true,
			new: false,
		},
		{
			name: "Female Daily Studio",
			value: "fds",
			pathname: process.env.NEXT_PUBLIC_ECM_DOMAIN,
			query: "",
			visible: true,
			new: false,
		},
		{
			name: "Girls Beyond",
			value: "gb",
			pathname: process.env.NEXT_PUBLIC_GB_DOMAIN,
			query: "",
			visible: true,
			new: true,
		},
	],

	ENCRYPT: {
		PASSWORD: "mommiespwdnyadisuruhtigapuluhdua",
		METHOD: "AES-256-CBC",
		HMAC: {},
	},

	FOOTER_SITE: [
		{
			alt: "logo-fd",
			src: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/logo-femaledaily.svg",
			url: `${process.env.NEXT_PUBLIC_ROOT_HOME}`,
			height: 28,
		},
		{
			alt: "logo-md",
			src: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/logo-md.svg",
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}`,
			height: 28,
		},
		{
			alt: "logo-fds",
			src: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/logo-fds.svg",
			url: `${process.env.NEXT_PUBLIC_ECM_DOMAIN}`,
			height: 40,
		},
		{
			alt: "logo-gb",
			src: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/logo-gb.svg",
			url: `${process.env.NEXT_PUBLIC_GB_DOMAIN}`,
			height: 40,
		},
	],
	BANNER: {
		POPUP_BANNER_MOBILE: "MDHome_popup_banner_mobile",
		POPUP_BANNER_DESKTOP: "MDHome_popup_banner_desktop",
	},
};
